@import './spacing.scss';
@import './colors.scss';

.no-underline {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.align-end {
  align-self: end;
}

.background-color-2 {
  background: $backgroundColor2;
}

.uppercaseText {
  text-transform: uppercase;
}

.whiteText {
  color: #ffffff;
}

.verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexJustifyContentSpaceEvenly {
  justify-content: space-evenly;
}

.flexJustifyContentSpaceBetween {
  justify-content: space-between;
}

.darkGreyText {
  color: $darkGrey;
}

.alignSelfCenter {
  align-self: center;
}

.alignSelfBaseline {
  align-self: baseline;
}

.nowrap {
  white-space: nowrap;
}

.flexGrow1 {
  flex-grow: 1;
}
.pointerEventsNone {
  pointer-events: none;
}

.noMargin {
  margin: 0;
}
