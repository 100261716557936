$backgroundColor2: #f3f2f1;

$lightGrey: #e0e5ec;
$darkGrey: #a4b2c6;
$dashTextGrey: #13293b;
$controlRed: #df394a;
$controlGreen: #107c10;
$controlYellow: #f5b300;

// ACS @fluentui/react
$buttonBorderGrey: rgb(225, 223, 221);
$buttonBorderDarkerGrey: rgb(138, 136, 134);

// @azure/communication-react
$darkThemeWhite: #252423; // this color is definied in @azure/communication-react - darkTheme.palette.white
$darkThemeNeutralLighter: #383735; // this color is definied in @azure/communication-react - darkTheme.palette.neutralLighter

// @fluentui/react-northstar hover highlight
$darkThemeForegroundActive : #7f85f5;
