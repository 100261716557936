@import 'RESOURCES/styles/colors.scss';

.headerControlsContainer {
  height: fit-content !important;
  max-width: 100%;
}
.sectionBorder {
  border: 2px solid $lightGrey;
  border-radius: 10px;
  padding-bottom: 1em;
  max-width: 30vw;
  min-width: fit-content;
}
.sectionTitle {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: $lightGrey;
  max-height: 2.5em;
  color: $darkGrey;
  text-transform: uppercase;

  .icon {
    height: 1em;
    width: 1em;
  }
}
.customPrimaryButton {
  background: $darkGrey !important;
  color: black !important;
  border-radius: 5px;
}
.customSecondaryButton {
  background: #ffffff;
  color: $darkGrey !important;
  border-radius: 5px;
  .ui-button {
    color: $darkGrey;
    font-weight: 300;
  }
}
.imageButton {
  width: 5em;
  height: 3em;
  border-radius: 5px;
  padding: 0.25em 1em;
  margin: 0.25em;
  text-align: center;
  cursor: pointer;
  img {
    vertical-align: middle;
    height: 1.5em;
    object-fit: contain;
  }
}

#headerControlsContainer {
  .volumeSlider .ui-slider {
    height: 100% !important;
    width: unset !important;
    max-width: 13vw;
    display: flex;
    margin: auto;
  }
  .ui-slider__input-wrapper {
    width: -webkit-fill-available !important;
    .ui-slider__track {
      background: $darkGrey !important;
    }
    .ui-slider__thumb {
      background: $darkGrey !important;
    }
  }

  .ui-menu__itemwrapper {
    color: $darkGrey !important;
    text-align: center;
  }
}
