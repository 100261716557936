@import 'RESOURCES/styles/colors.scss';

.messageArea {
  height: fit-content;
  width: auto;
  padding: 1em;
  border: 1px solid $controlYellow;
  border-radius: 0.1em;
  color: $controlYellow;
}

.hideArea {
  visibility: hidden;
}

.chatWindowFlex {
  overflow-y: hidden;
}

.flexColumnGap {
  column-count: 2;
}
