.recording-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.message {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
