@import 'RESOURCES/styles/colors.scss';

// common
.column-align {
  display: flex;
  align-items: center;
}

.button {
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  margin: 2px 0;
  /* black */

  background: #000000;
  /* button top */

  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.32), 0px 1.6px 3.6px rgba(0, 0, 0, 0.28);
  border-radius: 4px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
  color: #ffffff;

  width: 80px;
  height: 32px;

  // outline: none;
  // border: none;
  cursor: pointer;
}

.click-effect:active {
  opacity: 70%;
}
.redButton {
  background-color: $controlRed !important;
  border-radius: 5px;
}
.greenButton {
  background-color: $controlGreen !important;
}
.yellowButton {
  background-color: $controlYellow !important;
}
.greyButton {
  background-color: $lightGrey !important;
}
