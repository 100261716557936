.meeting-title {
  margin-bottom: 5%;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.dropdown-label {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}
.auaListItem{
  padding-left: 3em;
  
}
.auaEmailContent {
  padding: 2em;
  border: 1px solid grey;
  border-radius: 1em;
  word-wrap: break-word;
}
@media screen and (min-width: 600px) {
  .form-container {
    max-width: 550px;
  }
}
