.connectionErrorDialog.ui-dialog {
  box-shadow: none;
  background-color: transparent !important;
  .ui-dialog__content,
  .ui-dialog__header {
    color: white;
    text-shadow: 0 0 1em black;
    text-align: center;
  }
  .ui-dialog__header {
    justify-self: normal;
  }
}
