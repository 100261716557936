/*
  This .scss loop will create "margin helpers" and "padding helpers" class.
  
  It will generate several classes such as:
    .mr-2 which gives margin-right 8 pixels.
    .mr-1 gives MARGIN to the RIGHT 4 pixels.
    .pb-5 gives PADDING to the BOTTOM of 20 pixels
    .pl-4 gives PADDING to the LEFT of 16 pixels
  
  The first letter is "m" or "p" for MARGIN or PADDING
  Second letter is "t", "b", "l", "r", "x", "h" for TOP, BOTTOM, LEFT, RIGHT, HORIZONTAL, VERTICAL
  Third letter is the number of spacing in pixels.
*/

$spacing-values: (
  '0': 0px,
  '1': 4px,
  '2': 8px,
  '3': 12px,
  '4': 16px,
  '5': 20px,
  '6': 24px,
  '7': 28px,
  '8': 32px,
);

@each $space, $value in $spacing-values {
  .m-#{$space} {
    margin: #{$value};
  }

  .mx-#{$space} {
    margin-left: #{$value};
    margin-right: #{$value};
  }

  .my-#{$space} {
    margin-top: #{$value};
    margin-bottom: #{$value};
  }

  .ml-#{$space} {
    margin-left: #{$value};
  }

  .mr-#{$space} {
    margin-right: #{$value};
  }

  .mb-#{$space} {
    margin-bottom: #{$value};
  }

  .mt-#{$space} {
    margin-top: #{$value};
  }

  .p-#{$space} {
    padding: #{$value};
  }

  .px-#{$space} {
    padding-left: #{$value};
    padding-right: #{$value};
  }

  .py-#{$space} {
    padding-top: #{$value};
    padding-bottom: #{$value};
  }

  .pl-#{$space} {
    padding-left: #{$value};
  }

  .pr-#{$space} {
    padding-right: #{$value};
  }

  .pb-#{$space} {
    padding-bottom: #{$value};
  }

  .pt-#{$space} {
    padding-top: #{$value};
  }
}
