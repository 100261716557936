@import 'RESOURCES/styles/colors.scss';

.width30 {
  max-width: 30vw;
  overflow: hidden;
}
.participantCard:hover,
.participantCard:focus {
  border: 1px solid $darkGrey;
  border-radius: 5px;
  background-color: $darkGrey;
}
.bold {
  font-weight: bolder;
}
.light {
  font-weight: light;
}
