.snackbar {
  position: absolute;
  width: 100vw;
  top: 10px;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
}

.snackbar-alert {
  min-width: calc(min(80vw, 200px));
  max-width: 80vw;
  text-align: center;
}
