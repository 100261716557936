.video-gallery {
  height: 100%;
  width: 100% !important;
  display: flex;
  flex-grow: 1;
  background-color: gainsboro;
  position: relative;
}

[data-ui-id='stream-media-container'] {
  justify-content: center;
}

.button-left {
  position: absolute !important;
  top: 50%;
  z-index: 1000000;
  left: 0;
  margin: 1px;
}
.button-right {
  position: absolute !important;
  top: 50%;
  z-index: 1000000;
  right: 0;
  margin: 1px;
}