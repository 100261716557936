@import 'RESOURCES/styles/colors.scss';

.chatHeader {
  position: sticky;
  top: 0;
  padding: 1em;
}

.noListType {
  list-style-type: none;
  padding-left: unset;
  overflow-y: auto;
}

#chatWindow {
  margin-left: 1vw;
  padding: unset;
  overflow: hidden;
  width: 23vw;
  height: 100%;
  min-width: unset !important; // nullify min-width effect from sectionBorder class
  .ui-chat {
    position: relative;
    width: 100em !important;

    padding: 0.01em !important;
    .ui-chat__item {
      padding-top: 0.1em !important;
    }
    .ui-chat__item__message {
      margin-left: 0.1em !important;
      width: 100% !important;
    }
  }
  .ms-FocusZone.ui-chat {
    width: auto;
    position: unset;
    max-height: 62vh;
    overflow-y: auto;
    overflow-x: clip;
    margin: unset !important;
    padding: 0;
  }
  .ui-card {
    width: unset;
  }
  .ui-chat__message {
    height: unset !important;
    padding: 0.2em;
    border: 1pt solid whitesmoke;
    .ui-chat__message__content {
      width: 100em !important;
      white-space: pre-wrap;
      overflow-x: hidden;
      overflow-y: hidden;
      display: inline;
      word-wrap: break-word;
    }

    span .ui-chat__message__author {
      overflow: hidden;
      display: inline;
    }

    .ui-chat__message__bar {
      min-width: 10vw;
      padding: 0 !important;
    }
  }
}

.sendBox {
  margin: 0 0.25em;
  max-width: 100em;
  min-height: 3em !important;
  height: auto !important;
  max-height: fit-content !important;
  overflow-y: clip;
  #sendbox {
    margin-top: 0.1em;
    padding: 0px !important;
    margin-bottom: 0% !important;
    width: 100em;
    border: none !important;
    resize: none;
    height: unset !important;
    min-height: 2.5em;
    max-height: 1em;
  }
  #sendbox:focus-visible {
    border: none !important;
    border-color: none !important;
    border-width: 0 !important;
  }
  .inputError {
    background-color: $lightGrey;
  }
}

.chatNameBackground {
  background-color: $lightGrey;
}

.iconMiniButton {
  padding: 0.5em;
  object-fit: contain;
  flex-basis: 5% !important;
  cursor: pointer;
  svg {
    width: 1em;
  }
}

.messageThread {
  margin-top: 0.2em !important;
  height: 100% !important;
  overflow-y: scroll;
}

@media (max-width: 1208px) {
  // as per min-width of #interpreterContainer
  #chatWindow {
    width: 30%;
  }
}
