@import 'RESOURCES/styles/colors.scss';

$white: #ffffff;
$black: #000000;

.lang-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  border-radius: 0.2em;
  cursor: pointer;
  border: solid 1px $white;
  margin-bottom: 12px;
  background: #2c2c2c;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: $white;
}

.selected-lang-btn {
  color: $black;
  background: $white;
}

.bottomPadding {
  padding-bottom: 1em;
}
.disabled-cc {
  color: $white;
  background: #c4dddd66;
  pointer-events: none;
}

.height30 {
  height: '30%';
}

#languageSelectionDropdown {
  width: 85% !important;
}
.chatBorder {
  &:focus {
    outline: 0;
  }
  &:hover {
    color: $darkThemeForegroundActive;
  }
}
