@import 'RESOURCES/styles/colors.scss';
$white: #ffffff;

#akouoSelection {
  z-index: 3000;
  font-size: 14px;
  font-weight: 400;
  position: fixed !important;
  display: flex;

  .ms-Dropdown {
    height: 2.9em;
    min-width: 5em;
    max-width: -moz-fit-content;
    max-width: fit-content;

    .ms-Dropdown-title {
      border-color: transparent !important;
      height: 100%;
      line-height: 2.9em;
    }

    .ms-Dropdown-caretDownWrapper {
      height: 100%;

      i {
        vertical-align: -webkit-baseline-middle;
      }
    }
  }
}

.ms-Button--hasMenu {
  height: 2.9em;
}

.screenCenterSpinner.ms-Spinner {
  height: 100%;
  vertical-align: middle;
}

ul.ms-FocusZone.ui-chat {
  overflow: unset !important;
  width: 100% !important;
}

#apiError {
  .ms-MessageBar--error {
    padding: 3em;
  }
}

#languageSelectionError {
  position: fixed;
  width: 100%;
}

#mobileCaptionsContainer {
  position: fixed;
  top: 1%;
  left: 5%;
  background: transparent;
  z-index: 3;
  width: 90%;
  height: 80%;
  font-weight: 600;

  #captionsIframe {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
  }
}

.captionsButton {
  align-items: center;
  display: flex;
  font-weight: 600;
  background: rgb(37, 36, 35);
  margin-left: 1em !important;
  padding-left: 1em;
  padding-right: 1em;
  cursor: pointer;
  border: 1px solid $buttonBorderDarkerGrey;
  border-radius: 0.3em;

  &:hover {
    background: $darkThemeNeutralLighter;
  }
}

.disabled-cc {
  color: $white;
  background: #c4dddd66;
  pointer-events: none;
}

#akouoSplitbutton {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 0;
}

.noRightBorderLogo {
  background: $darkThemeWhite;
  border: 1px solid $buttonBorderDarkerGrey;
  border-right: none;
  width: 3.6em;
  height: 2.95em;
  border-radius: 0.2em;
  text-align: -webkit-center;
}

.noRightBorderLogoLoading {
  background: $darkThemeNeutralLighter;
  border: none;
  border-right: none;
  width: 2.9em;
  text-align: -webkit-center;
}

#uaBrandingLogo {
  display: inline-flex;
  vertical-align: text-bottom;
  padding-left: 0.7em !important;
}

.alignCenter {
  text-align: center;
  display: flex;
}

.languageDropdown {
  position: fixed;
  bottom: 60px;
  background-color: rgb(37, 36, 35);
  border-radius: 2px;
}

.languageList {
  list-style-type: none;
  margin: 1em;
  padding: 0;
}

.languageOption{
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  margin-bottom: 0.5em;
}

.languageOption > button {
  text-align: left;
}

.auaLanguageVolumeControls{
  border-left: 1px solid #3b3a39;
  padding-left: 0.5em;
  align-items: center;
  gap: 1em;
}

// Changing icon color in iconButton
.drawerButton i{
  color: rgb(255, 255, 255);
}

.disabled-slider{
  opacity: 0.5;
}

.otpInput {
  margin: 0.5rem;
}

.otpRetryAttempt {
  padding-left: 0.6rem !important;
}

.otpTitle {
  padding-left: 1.9rem;
  padding-bottom: 0.5rem;
}